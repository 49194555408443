import { Component, Inject, Injector, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import {
  actionButtonOptions,
  amountInputOptions,
  defaultInputOptions,
} from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/accounts-add-edit/component.options";
import { Balances } from "@bitwarden/web-vault/app/models/enum/balance.enum";
import { InputTypes } from "@bitwarden/web-vault/app/models/enum/inputTypes.enum";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { UserLocationCurrencyEnum } from "@bitwarden/web-vault/app/models/enum/user-location.enum";
import { BalanceForm } from "@bitwarden/web-vault/app/models/types/account.types";
import { GlossInputOptions } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { HelperNumericInput } from "@bitwarden/web-vault/app/shared/utils/helper-numeric-input";
import { BookBalance } from "@bitwarden/web-vault/app/shared/utils/helper.book/balance";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

@Component({
  selector: "app-account-balance",
  templateUrl: "./account-balance.component.html",
})
export class AccountBalanceComponent implements OnInit {
  form: FormGroup;
  balanceInputOptions: GlossInputOptions = amountInputOptions;
  timeInputOptions: GlossInputOptions = {
    ...defaultInputOptions,
    label: this.i18nService.t("time"),
    placeholder: "00:00:00",
    isRequired: false,
    type: InputTypes.time,
    onInput: (event: Event) => this.onInput(event, "time"),
  };
  descriptionInputOptions: GlossInputOptions = {
    ...defaultInputOptions,
    label: this.i18nService.t("description"),
    placeholder: this.i18nService.t("description"),
    onInput: (event: Event) => this.onInput(event, "description"),
    isRequired: true,
  };
  saveButtonOptions: ActionButton;
  loading = false;
  currency: string;
  title = this.i18nService.t("enterAccountData");

  balances = [
    { label: this.i18nService.t("openingBalance"), value: Balances.opening, defaultSelected: true },
    {
      label: this.i18nService.t("closingBalance"),
      value: Balances.closing,
      defaultSelected: false,
    },
  ];
  selectedBalance: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accountView: AccountView;
      closeDialogue: CallableFunction;
    },
    private formBuilder: FormBuilder,
    private dateFormat: DateFormat,
    private injector: Injector,
    private preferenceService: PreferenceService,
    private i18nService: I18nService
  ) {
    Promise.resolve(this.initializeForm());
  }

  ngOnInit() {
    this.balanceInputOptions = {
      ...this.balanceInputOptions,
      label: this.i18nService.t("amount"),
      onInput: (event: Event) => this.onInputBalance(event),
      inputBlurred: () => this.clearDefaultValue(),
    };

    this.saveButtonOptions = new ActionButton({
      ...actionButtonOptions,
      onClick: this.submit.bind(this),
    });
  }

  // <editor-fold desc="Form State">
  private async initializeForm() {
    this.selectedBalance = Balances.opening;
    const { AU, HK } = UserLocationCurrencyEnum;
    const userLocation = await this.preferenceService.get(PreferenceType.userLocation);
    this.currency = userLocation === "AU" ? AU : HK;

    this.form = this.formBuilder.group({
      currency: [this.currency, Validators.required],
      balance: [null, [HelperNumericInput.isNumberValidator, Validators.required]],
      date: [this.dateFormat.getDateStringFromStamp(new Date().getTime()), Validators.required],
      time: [null],
      description: [null, Validators.required],
      type: this.selectedBalance,
      transactionStatus: TransactionStatusEnum.manual_balance,
    });
  }

  selectBalance(value: string) {
    this.selectedBalance = value;
    this.form.controls.type.setValue(value);
  }

  // </editor-fold desc="Form State">

  // <editor-fold desc="Fields State">
  selectCurrency(selectedCurrency: string) {
    this.form.get("currency")?.setValue(selectedCurrency);
  }

  onInputBalance(event: Event) {
    HelperNumericInput.onInput(event, this.form.controls.balance);
    const target = event.target as HTMLInputElement;
    this.form.controls.balance.setValue(target.value);
  }

  onInput(event: Event, controlName: string) {
    const target = event.target as HTMLInputElement;
    this.form.get(controlName)?.setValue(target.value);
  }

  clearDefaultValue() {
    HelperNumericInput.clearDefaultValue(this.form.controls.balance);
  }

  onDateChange(date: string) {
    this.form.controls.date.setValue(date);
    this.form.controls.date.markAsTouched();
  }

  // todo fix this to trigger properly
  triggerDateValidation() {
    if (!this.form.controls.date.touched) {
      this.onDateChange("");
    }
  }

  // </editor-fold desc="Fields State">

  resetForm() {
    this.saveButtonOptions.enableButton(true);
    this.loading = false;
  }

  async submit() {
    this.triggerDateValidation();
    this.loading = true;

    if (!this.form.valid) {
      this.resetForm();
      return;
    }

    const balanceForm: BalanceForm = this.form.value;
    const balance = new BookBalance(this.data.accountView, balanceForm, this.injector);
    const result = await balance.process();

    if (result) {
      this.data.closeDialogue();
      this.loading = false;
    } else {
      this.resetForm();
    }
  }
}
