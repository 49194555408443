import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import {
  actionButtonOptions,
  amountInputOptions,
  defaultInputOptions,
} from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/accounts-add-edit/component.options";
import { InputTypes } from "@bitwarden/web-vault/app/models/enum/inputTypes.enum";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { UserLocationCurrencyEnum } from "@bitwarden/web-vault/app/models/enum/user-location.enum";
import { GlossInputOptions } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { HelperNumericInput } from "@bitwarden/web-vault/app/shared/utils/helper-numeric-input";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

@Component({
  selector: "account-transaction",
  templateUrl: "./transaction.component.html",
})
export class TransactionComponent implements OnInit {
  form: FormGroup;
  amountFieldOptions: GlossInputOptions = amountInputOptions;
  timeInputOptions: GlossInputOptions = {
    ...defaultInputOptions,
    label: this.i18nService.t("time"),
    placeholder: "00:00:00",
    isRequired: false,
    type: InputTypes.time,
    onInput: (event: Event) => this.onInput(event, "time"),
  };
  descriptionInputOptions: GlossInputOptions = {
    ...defaultInputOptions,
    label: this.i18nService.t("description"),
    placeholder: this.i18nService.t("description"),
    onInput: (event: Event) => this.onInput(event, "description"),
    isRequired: true,
  };
  saveButtonOptions = new ActionButton({
    ...actionButtonOptions,
    onClick: this.submit.bind(this),
  });
  maxDate = new Date();
  loading = false;
  currency: string;
  title = this.i18nService.t("enterAccountData");

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accountView: AccountView;
      closeDialogue: CallableFunction;
    },
    private formBuilder: FormBuilder,
    private dateFormat: DateFormat,
    private preferenceService: PreferenceService,
    private i18nService: I18nService,
    private transactionService: TransactionService,
    private globalService: GlobalService
  ) {
    Promise.resolve(this.initializeForm());
  }

  ngOnInit() {
    this.amountFieldOptions = {
      ...this.amountFieldOptions,
      label: this.i18nService.t("amount"),
      onInput: (event: Event) => this.onInputAmount(event),
      inputBlurred: () => this.clearDefaultValue(),
    };
  }

  // <editor-fold desc="Form State">
  private async initializeForm() {
    const { AU, HK } = UserLocationCurrencyEnum;
    const userLocation = await this.preferenceService.get(PreferenceType.userLocation);
    this.currency = userLocation === "AU" ? AU : HK;

    this.form = this.formBuilder.group({
      currency: [this.currency, Validators.required],
      amount: [null, [HelperNumericInput.isNumberValidator, Validators.required]],
      date: [this.dateFormat.getDateStringFromStamp(new Date().getTime()), Validators.required],
      time: [null],
      description: [null, Validators.required],
      transactionStatus: TransactionStatusEnum.transaction,
    });
  }
  // </editor-fold desc="Form State">

  // <editor-fold desc="Fields State">
  selectCurrency(selectedCurrency: string) {
    this.form.controls.currency.setValue(selectedCurrency);
  }

  onInputAmount(event: Event) {
    HelperNumericInput.onInput(event, this.form.controls.amount);
    const target = event.target as HTMLInputElement;
    this.form.controls.amount.setValue(target.value);
  }

  onInput(event: Event, controlName: string) {
    const target = event.target as HTMLInputElement;
    this.form.get(controlName)?.setValue(target.value);
  }

  clearDefaultValue() {
    HelperNumericInput.clearDefaultValue(this.form.controls.amount);
  }

  onDateChange(date: string) {
    this.form.controls.date.setValue(date);
    this.form.controls.date.markAsTouched();
  }

  // todo fix this to trigger properly
  triggerDateValidation() {
    if (!this.form.controls.date.touched) {
      this.onDateChange("");
    }
  }

  // </editor-fold desc="Fields State">

  async submit() {
    this.triggerDateValidation();

    if (this.form.valid) {
      this.transactionService.createNormalTransaction(
        this.data.accountView.originalBook,
        this.form.value
      );
      this.data.closeDialogue();
      this.globalService.showSuccessMessage("success", "transactionAddedSuccessfully");
    } else {
      this.saveButtonOptions.enableButton(true);
    }
  }
}
