import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  ColDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { SyncActionButtonsCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/sync-action-buttons-renderer/sync-action-buttons-cell-renderer.component";
import { SyncStatusRendererComponent } from "@bitwarden/web-vault/app/components/cell-renderers/sync-status-renderer/sync-status-renderer.component";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { TransactionBasiqImporter } from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { SyncState, SyncStatus } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountSyncService } from "@bitwarden/web-vault/app/services/syncing/account.sync.service";
import { GlossSyncService } from "@bitwarden/web-vault/app/services/syncing/gloss-sync.service";
import { SyncStore } from "@bitwarden/web-vault/app/services/syncing/syncing.store";

const commonDefs = {};

@Component({
  selector: "app-sync",
  templateUrl: "./sync.component.html",
})
export class SyncComponent implements OnInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();
  showSpinner = false;
  syncState: SyncState;
  readonly vm$ = this.syncStore.vm$;

  constructor(
    private transactionBasiqImporter: TransactionBasiqImporter,
    private glossSyncService: GlossSyncService,
    private basiqAuth: BasiqAuth,
    private route: ActivatedRoute,
    private i18nService: I18nService,
    private readonly syncStore: SyncStore
  ) {}

  autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  };

  syncStatus: SyncStatus = null;

  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [
    {
      headerName: "Account",
      field: "account",
      ...commonDefs,
      valueGetter: (params) => params.data?.accountView?.name,
    },
    {
      headerName: "Status",
      field: "status",
      ...commonDefs,
      cellRenderer: SyncStatusRendererComponent,
    },
    {
      headerName: "Info",
      field: "info",
      ...commonDefs,
      valueFormatter: (p) => p.data.point?.data?.message,
    },
    {
      headerName: "Last Synced At",
      field: "lastSyncedAt",
      ...commonDefs,
      valueFormatter: (p) => p.data.lastSyncedAt?.getDateTimeAndZoneString(),
    },
    {
      //TODO - Add a buttons depending on p.data.point.data.actions
      headerName: "Action",
      field: "action",
      ...commonDefs,
      cellRenderer: SyncActionButtonsCellRenderer,
    },
  ];
  context = { componentParent: this };
  mergeButton: ActionButton;
  syncButton = new ActionButton({
    text: this.i18nService.t("sync"),
    class: "neutral",
    isEnabled: true,
    onClick: this.syncManually.bind(this),
    icon: "sync-all",
  });

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((state) => {
      this.syncState = state;
    });

    /*    this.glossSyncService.syncStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (newSyncStatus: SyncStatus) => {
        this.syncStatus = newSyncStatus;
        if (newSyncStatus?.isFinished) {
          this.showSpinner = false;
          this.glossSyncService.updateFailedConnectorsInBlobby();
        }
      },
    });*/
    const isSync = this.route.snapshot.paramMap.get("sync");
    if (isSync) {
      this.syncManually();
      this.clearUrlParams();
    }

    this.mergeButton = new ActionButton({
      text: this.i18nService.t("merge"),
      class: "neutral",
      isEnabled: !this.syncState.isCompleted,
      onClick: this.importSyncedData.bind(this),
      icon: "merge",
    });
  }

  clearUrlParams() {
    const currentURL = new URL(window.location.href);

    currentURL.searchParams.delete("sync");
    window.history.pushState({}, "", currentURL.toString());
  }

  merge(accountStatus: AccountSyncService) {
    //TODO - Implement the connect logic
  }

  async connect(accountStatus: AccountSyncService) {
    //TODO - Implement the connect logic
    await this.basiqAuth.connectToInstitution(accountStatus.accountView.institution.basiqId);
  }

  async consent(accountStatus: AccountSyncService) {
    await this.basiqAuth.requestBasiqConsent();
  }

  async retry(accountStatus: AccountSyncService) {
    await this.syncStore.startSync();
  }

  onFirstDataRendered(params: any): void {
    params.columnApi.sizeColumnsToFit();
  }

  onGridReady(params: any): void {
    params.columnApi.applyColumnState({
      state: [{ colId: "account", sort: "asc" }],
    });
  }

  syncManually() {
    this.syncStore.startSync();
  }

  async importSyncedData() {
    try {
      this.showSpinner = true;
      await this.glossSyncService.mergeSyncedData(this.syncState.accountStatus);
      this.showSpinner = false;
      location.reload();
    } catch (e) {
      this.showSpinner = false;
    }
  }
}
