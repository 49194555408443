<div
  class="tw-relative tw-flex tw-h-12 tw-w-64 tw-items-center tw-justify-around tw-rounded-full tw-bg-neutral-50 tw-shadow-button"
>
  <mat-select
    panelClass="tw-bg-white tw-shadow-button tw-mt-11 tw-w-full tw-gap-1 tw-flex tw-flex-col tw-px-1"
    [(value)]="selectedValue"
    (selectionChange)="onSelectionChange(selectedValue)"
    [id]="selectElementId"
  >
    <mat-select-trigger>
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-1">
        <span>{{ getDisplayValue(selectedValue) }}</span>
        <svg class="tw-h-7 tw-w-7">
          <use xlink:href="images/sprite.svg#down-arrow" />
        </svg>
      </div>
    </mat-select-trigger>
    <mat-option
      class="tw-w-full tw-rounded-xl tw-p-1"
      *ngFor="let item of itemList"
      [value]="!item[valueKey] ? item : item[valueKey]"
    >
      {{ !item[displayKey] ? item : item[displayKey] }}
    </mat-option>
  </mat-select>
</div>
