import { Button } from "@bitwarden/web-vault/app/components/buttons/gloss-button/button";

export class ActionButton extends Button {
  constructor(properties: {
    type?: string;
    link?: string;
    onClick?: () => void;
    isEnabled?: boolean;
    icon?: string;
    class?: string;
    text?: string;
  }) {
    super(properties);
  }
}
