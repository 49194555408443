import { Injectable } from "@angular/core";

import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { AccountTypes } from "@bitwarden/web-vault/app/models/enum/account.enum";
import { BasiqTypes } from "@bitwarden/web-vault/app/models/types/account.types";

import { Book } from "../../../models/data/blobby/book.data";
import { Category } from "../../../models/data/blobby/category.data";
import { Institution } from "../../../models/data/blobby/institution.data";
import { SourceBook } from "../../../models/data/blobby/source-book";
import { BookResponse } from "../../../models/data/response/book.response";
import { BasiqAccountType } from "../../../models/types/basiq.types";
import { Origin } from "../../../models/types/general-types";
import { InstitutionAccount } from "../../../models/types/institution.type";
import { DataRepositoryService } from "../../../services/DataRepository/data-repository.service";
import { CategoryService } from "../../../services/DataService/category/category.service";
import { ClassificationService } from "../../../services/DataService/classification/classification.service";
import { InstitutionService } from "../../../services/DataService/institution/institution.service";

@Injectable({
  providedIn: "root",
})
export class BasiqAccountService {
  constructor(
    private dataRepositoryService: DataRepositoryService,
    private classificationService: ClassificationService,
    private categoryService: CategoryService,
    private institutionService: InstitutionService
  ) {}

  private getAccountTypeFromInstitution(
    institution: Institution,
    basiqAccount: BasiqAccountType
  ): InstitutionAccount {
    return institution.availableAccounts.find((accountType) => {
      return accountType.name.toLowerCase() === basiqAccount.class.product.toLowerCase();
    });
  }

  private async generateAccountType(
    basiqAccount: BasiqAccountType,
    institution: Institution
  ): Promise<InstitutionAccount> {
    const newAccountType: InstitutionAccount = {
      name: basiqAccount.class.product,
      interestRates: [],
      id: crypto.randomUUID(),
    };
    if (institution.availableAccounts) {
      institution.availableAccounts.push(newAccountType);
    } else {
      institution.availableAccounts = [newAccountType];
    }
    await this.institutionService.updateInstitution(institution);
    return newAccountType;
  }

  private async getAccountType(basiqAccount: BasiqAccountType, institution: Institution) {
    let accountType: InstitutionAccount = null;
    if (institution?.availableAccounts?.length) {
      accountType = this.getAccountTypeFromInstitution(institution, basiqAccount);
    }

    if (!accountType) {
      accountType = await this.generateAccountType(basiqAccount, institution);
    }

    return accountType;
  }
  async saveNewAccounts(newBasiqBasedAccounts: BasiqAccountType[]): Promise<Book[]> {
    const newAccounts: Book[] = [];
    const basiqBasedInstitutions = await this.dataRepositoryService.getAllBasiqBasedInstitutions();
    const defaultClassification =
      await this.classificationService.getGeneralDefaultClassification();
    const defaultCategory: Category = await this.categoryService.getGeneralDefaultCategory();

    for (const basiqAccount of newBasiqBasedAccounts) {
      const newBook = await this.createNewBook(
        basiqAccount,
        basiqBasedInstitutions,
        defaultClassification,
        defaultCategory
      );
      newAccounts.push(newBook);
    }

    return newAccounts;
  }

  /*TODO set time-zone better than hardcoding */
  private getAccountResponseObject(
    basiqAccount: BasiqAccountType,
    institution: Institution,
    accountType: InstitutionAccount,
    defaultClassification: Classification,
    defaultCategory: Category
  ): any {
    return {
      _id: basiqAccount.id,
      name: basiqAccount.name,
      currency: basiqAccount.currency,
      timezone: "Australia/Sydney",
      defaultClassifications: [
        {
          classificationId: defaultClassification.id,
          weight: 1,
          roundingDefault: true,
          name: defaultClassification.name,
        },
      ],
      defaultCategories: [
        {
          categoryId: defaultCategory.id,
          weight: 1,
          roundingDefault: true,
          name: defaultCategory.name,
        },
      ],
      balance: this.isCreditType(basiqAccount.class.type)
        ? this.getCreditCardBalance(basiqAccount)
        : basiqAccount.availableFunds,
      institutionLink: {
        institutionId: institution?.id,
        institutionAccountId: accountType.id,
      },
      origin: Origin.basiq,
      basiqAccountId: basiqAccount.id,
      type: basiqAccount.class.type,
    };
  }

  private getCreditCardBalance(basiqAccount: BasiqAccountType): number {
    const availableFunds = new GlossNumber().setToGlossNumberObj({
      amount: basiqAccount.availableFunds,
    });
    const creditLimit = new GlossNumber().setToGlossNumberObj({ amount: basiqAccount.creditLimit });

    availableFunds.subtract(creditLimit);
    return availableFunds.amount;
  }

  isCreditType(type: BasiqTypes): boolean {
    return type === AccountTypes.creditCard || type === AccountTypes.mortgage;
  }

  private getSourceBookObject(bookObj: any): any {
    return {
      source: bookObj.name,
      accountId: bookObj._id,
      origin: Origin.basiq,
    };
  }

  private async createNewSourceBook(bookObj: any): Promise<void> {
    const sourceBookObject = await this.getSourceBookObject(bookObj);
    const newSourceBook = new SourceBook(sourceBookObject);
    await this.dataRepositoryService.createSourceBook(newSourceBook);
  }

  private async createNewBook(
    basiqAccount: BasiqAccountType,
    basiqBasedInstitutions: Institution[],
    defaultClassification: Classification,
    defaultCategory: Category
  ): Promise<Book> {
    const institution = basiqBasedInstitutions.find(
      (basiqBasedInsto) => basiqBasedInsto.basiqId === basiqAccount.institution
    );
    const accountType = await this.getAccountType(basiqAccount, institution);
    const accountResponseObject = this.getAccountResponseObject(
      basiqAccount,
      institution,
      accountType,
      defaultClassification,
      defaultCategory
    );
    await this.createNewSourceBook(accountResponseObject);

    const newBook = new Book(new BookResponse(accountResponseObject));
    return await this.dataRepositoryService.createBook(newBook);
  }

  async getBasiqBasedAccounts(accountsFromBasiq: BasiqAccountType[]): Promise<Book[]> {
    const existingAccountsBasedOnBasiq = await this.dataRepositoryService.getAllBasiqBasedBooks();
    const existingSourceAccountsBasedOnBasiq =
      await this.dataRepositoryService.getBasiqSourceBooks();
    const newBasiqBasedAccounts: BasiqAccountType[] = [];
    const updatedBasiqBasedAccounts: Book[] = [];
    for (const basiqAccount of accountsFromBasiq) {
      const existingSourceAccounts = existingSourceAccountsBasedOnBasiq.filter(
        (existingAccount) => existingAccount.source === basiqAccount.name
      );
      let existingAccount = null;

      if (existingSourceAccounts && existingSourceAccounts.length > 0) {
        existingAccount = existingAccountsBasedOnBasiq.find((existingBook) =>
          existingSourceAccounts.find((sa) => sa.accountId === existingBook.id)
        );
      }

      if (!existingAccount) {
        newBasiqBasedAccounts.push(basiqAccount);
      } else {
        existingAccount.balance = this.isCreditType(basiqAccount.class.type)
          ? Number(this.getCreditCardBalance(basiqAccount))
          : Number(basiqAccount.availableFunds);
        existingAccount.basiqAccountId = basiqAccount.id;
        const updatedBook = await this.dataRepositoryService.updateBook(existingAccount);
        updatedBasiqBasedAccounts.push(updatedBook);
      }
    }

    const savedNewBasiqBasedAccounts = newBasiqBasedAccounts?.length
      ? await this.saveNewAccounts(newBasiqBasedAccounts)
      : [];

    // Combine existingAccountsBasedOnBasiq and savedNewBasiqBasedAccounts
    return [...updatedBasiqBasedAccounts, ...savedNewBasiqBasedAccounts];
  }
}
