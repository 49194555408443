<div class="progress-bar-container">
  <span class="in-progress progress-bar-label" *ngIf="isInfo">{{ infoText }}</span>
  <span class="in-progress progress-bar-label" *ngIf="!isInfo"
    >{{ csvProgressBar.importingLabel }} <span *ngIf="parentLabel">{{ parentLabel }}</span>
    {{ csvProgressBar.loadingPercent }}%</span
  >

  <mat-progress-bar
    class="gloss-progress-bar"
    mode="buffer"
    [value]="csvProgressBar.loadingPercent"
    color="warn"
  >
  </mat-progress-bar>
</div>
